import { useEffect, useState } from "react";
import { AddMovieWrapper } from "../Styles/AddMovie-Style";
import axios from "axios";
import { Add_Screens_Shows_URL, Fetch_Active_Screens_URL, Fetch_Movies_URL } from "../API/Api";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Bounce } from 'react-toastify';




function AddMovies() {

    const hours = ['00','01','02','03','04','05','06','07','08','09','10','11','12'];
    const minutes = ['00','05','10','15','20','25','30','35','40','45','50','55'];
    const slotes = ['AM','PM'];

    const [theater, setTheater] = useState('');
    const [movie, setMovie] = useState('');
    const [language, setLanguage] = useState('');
    const [hour, setHour] = useState('');
    const [minute, setMinute] = useState('');
    const [slot, setSlot] = useState('');
    const [movies, setMovies] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [formattedDate, setFormattedDate] = useState('');
    const [reload, setReload] = useState(false);
    const [movieDropdown, setMovieDropdwon] = useState(false);
    const [languageDropdown, setLanguageDropdwon] = useState(false);
    const [hourDropdown, setHourDropdwon] = useState(false);
    const [minuteDropdown, setMinuteDropdwon] = useState(false);
    const [slotDropdown, setSlotDropdwon] = useState(false);
    const [screens, setScreens] = useState([]);
    const [selectedScreen, setselectedScreen] = useState([]);
    const [selectScreen, setSelectScreen] = useState('');
    const [screenDropdown, setScreenDropdown] = useState(false);

    useEffect(() => {
        axios.get(Fetch_Movies_URL)
        .then(res => {
            // console.log(res.data);
            setMovies(res.data.movies);
        })
        .catch(err => {
            // console.log(err);
            setMovies('');
        })
    }, [reload]);

    useEffect(() => {

        const user = localStorage.getItem("User");

        axios.get(`${Fetch_Active_Screens_URL}?user=${user}`)
        .then(res => {
            console.log(res);
            setScreens(res.data.screens);
            setTheater(res.data.theater);
        })
        .catch(err => {
            // console.log(err);
            setScreens([]);
        })

    }, [reload]);

    const handleDate = (e) => {
        const date = new Date(e.target.value);
        setFormattedDate(formatDate(date));
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: 'numeric', month: 'short', year: 'numeric' };
        return date.toLocaleDateString('en-GB', options).replace(/(\d+) (\w+) (\d+)/, '$1 $2, $3');
    };

    const handleMovieDropdown = () => {
        setMovieDropdwon(!movieDropdown);
        setLanguageDropdwon(false);
    };

    const handleLanguageDropdown = () => {
        setLanguageDropdwon(!languageDropdown);
        setMovieDropdwon(false);
    };

    const handleLanguages = (languages) => {
        const languageArray = languages.split(', ').map(language => language.trim());
        setLanguages(languageArray);
    };

    const handleScreens = () => {
        setScreenDropdown(!screenDropdown);
    };

    const handleHours = () => {
        setHourDropdwon(!hourDropdown);
        setMinuteDropdwon(false);
        setSlotDropdwon(false);
    };

    const handleMinutes = () => {
        setHourDropdwon(false);
        setMinuteDropdwon(!minuteDropdown);
        setSlotDropdwon(false);
    };

    const handleSlots = () => {
        setHourDropdwon(false);
        setMinuteDropdwon(false);
        setSlotDropdwon(!slotDropdown);
    };

    function handleMovieSearch(e) {
        const searchValue = e.target.value.toLowerCase();
        const filtered = movies.filter(movie => {
            return movie.name.toLowerCase().includes(searchValue.toLowerCase());
        });
        setMovies(filtered);
    }


    const handleAddMovies = (e) => {
        e.preventDefault();

        const time = `${hour}:${minute} ${slot}`;

        const inputs = {
            theater_name: theater,
            screen: selectScreen,
            screen_id: selectedScreen.screen_id,
            language: language,
            movie_name: movie,
            date: formattedDate,
            time: time
        }

        axios.post(Add_Screens_Shows_URL, inputs, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        })
        .then(res => {
            console.log(res);
            if(res.data.status == "200"){
                toast.success('Movie Added Successfully !!', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    transition: Bounce,
                });
                setHour('');
                setMinute('');
                setSlot('');
                setMovie('');
                setLanguage('');
                setSelectScreen('');
                setSelectScreen('');
                document.getElementById('date').value = "";
            }
        })
        .catch(err => {
            console.log(err)
        })
    };

    return(
        <>
            <AddMovieWrapper>
                <div className="wrapper_inner">
                    <div className="header_part">
                        <h4>Add Movies Screen wise</h4>
                    </div>
                    <div className="form_part">
                        <div className="sec_inner">
                            <form onSubmit={handleAddMovies}>
                                <div className="form_inner">
                                    <div className="form_box">
                                        <div className="selectBox movie">
                                            <input type="text" value={movie} id="movie" required />
                                            <div className="dropdown_btn" onClick={handleMovieDropdown}>
                                                <p>{movie}</p>
                                                <span>Select Movie</span>
                                                <i className={`fa-solid fa-angle-down ${movieDropdown ? 'active' : ''}`}></i>
                                            </div>
                                            <div className={`dropdown ${movieDropdown ? 'active' : ''}`}>
                                                <div className="search_sec">
                                                    <div className="search_inner">
                                                        <i className="fa-solid fa-magnifying-glass"></i>
                                                        <input type="text" className="search_input" placeholder="Search..." onChange={handleMovieSearch} />
                                                    </div>
                                                </div>
                                                <ul>
                                                    {
                                                        movies.map((movie, index) => 
                                                            <li key={index}
                                                                onClick={(e) => {
                                                                    setMovie(movie.name)
                                                                    setMovieDropdwon(false)
                                                                    handleLanguages(movies.find(movie => movie.name === e.target.textContent).languages)
                                                                }}
                                                            >{movie.name}</li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="selectBox">
                                            <input type="text" value={language} id="language" required />
                                            <div className="dropdown_btn" onClick={handleLanguageDropdown}>
                                                <p>{language}</p>
                                                <span>Select Language</span>
                                                <i className={`fa-solid fa-angle-down ${languageDropdown ? 'active' : ''}`}></i>
                                            </div>
                                            <div className={`dropdown ${languageDropdown ? 'active' : ''}`}>
                                                <ul>
                                                    {
                                                        languages.map((language, index) => 
                                                            <li key={index}
                                                                onClick={() => {
                                                                    setLanguage(language)
                                                                    setLanguageDropdwon(false)
                                                                }}
                                                            >{language}</li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="selectBox">
                                            <input type="text" value={selectScreen} required />
                                            <div className="dropdown_btn" onClick={handleScreens}>
                                                <p>{selectScreen}</p>
                                                <span>Select Screen</span>
                                                <i className={ `fa-solid fa-angle-down ${screenDropdown? 'active':''}`}></i>
                                            </div>
                                            <div className={`dropdown ${screenDropdown? 'active':''}`}>
                                                <ul>
                                                    {
                                                        screens.map((screen, index) => 
                                                            <li key={index}
                                                                onClick={() => {
                                                                    setselectedScreen(screen)
                                                                    setSelectScreen(screen.screen)
                                                                    setScreenDropdown(false)
                                                                }}
                                                            >{screen.screen}</li>
                                                        )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="date_box">
                                            <input type="date" onChange={handleDate} id="date" required />
                                            <span>Date</span>
                                        </div>
                                        <div className="time_box">
                                            <h5>Time:</h5>
                                            <input type="text" />
                                            <div className="time_select_box">
                                                <input type="text" value={hour} required />
                                                <div className="select_btn" onClick={handleHours}>
                                                    <p>{hour}</p>
                                                    <i className={`fa-solid fa-angle-down ${hourDropdown? 'active':''}`}></i>
                                                </div>
                                                <div className={`options ${hourDropdown? 'active':''}`}>
                                                    <ul>
                                                        {
                                                            hours.map((hour, index) => 
                                                                <li key={index}
                                                                    onClick={() => {
                                                                        setHour(hour)
                                                                        setHourDropdwon(false)
                                                                    }}
                                                                >{hour}</li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <span>:</span>
                                            <div className="time_select_box">
                                                <input type="text" value={minute} required />
                                                <div className="select_btn" onClick={handleMinutes}>
                                                    <p>{minute}</p>
                                                    <i className={`fa-solid fa-angle-down ${minuteDropdown? 'active':''}`}></i>
                                                </div>
                                                <div className={`options ${minuteDropdown? 'active':''}`}>
                                                    <ul>
                                                        {
                                                            minutes.map((minute, index) => 
                                                                <li key={index}
                                                                    onClick={() => {
                                                                        setMinute(minute)
                                                                        setMinuteDropdwon(false)
                                                                    }}
                                                                >{minute}</li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                            <div className="time_select_box slot">
                                                <input type="text" value={slot} required />
                                                <div className="select_btn" onClick={handleSlots}>
                                                    <p>{slot}</p>
                                                    <i className={`fa-solid fa-angle-down ${slotDropdown? 'active':''}`}></i>
                                                </div>
                                                <div className={`options ${slotDropdown? 'active':''}`}>
                                                    <ul>
                                                        {
                                                            slotes.map((slot, index) => 
                                                                <li key={index}
                                                                    onClick={() => {
                                                                        setSlot(slot)
                                                                        setSlotDropdwon(false)
                                                                    }}
                                                                >{slot}</li>
                                                            )
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="submit_btn"><button type="submit">Submit</button></div>
                            </form>
                        </div>
                    </div>
                </div>
            </AddMovieWrapper>
        </>
    );
}


export default AddMovies;
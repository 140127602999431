import { useEffect, useState } from "react";
import { SidebarWrapper } from "../Styles/Navigations-Style";
import { NavLink, useLocation } from "react-router-dom";




function Sidebar({setPageName}) {

    const location = useLocation();

    const [screenDropdown, setScreenDropdown] = useState(false);
    const [reportDropdown, setReportDropdown] = useState(false);
    const [movieDropdown, setMovieDropdown] = useState(false);


    useEffect(() => {

        const formatPathName = (pathName) => {
            return pathName.split('-')
                .map(word => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ');
        };

        const pathName = location.pathname.split('/').filter(Boolean).pop() || 'Dashboard';
        const formattedPageName = formatPathName(pathName);

         if(pathName == "all-movies" || pathName == "today-showing" || pathName == "request-movie" || pathName == "add-movies" || pathName == "reviews"){
            setMovieDropdown(true);
        } else {
            setMovieDropdown(false);
        }

        if(pathName == "screens" || pathName == "seat-layouts" || pathName == "screen-settings"){
            setScreenDropdown(true);
        } else {
            setScreenDropdown(false);
        }

        setPageName(formattedPageName);
    },[location]);

    function handleScreenDropdown() {
        setScreenDropdown(!screenDropdown);
        setReportDropdown(false);
        setMovieDropdown(false);
    }

    function handleReportDropdown() {
        setReportDropdown(!reportDropdown);
        setScreenDropdown(false);
        setMovieDropdown(false);
    }

    function handleMovieDropdown() {
        setMovieDropdown(!movieDropdown);
        setReportDropdown(false);
        setScreenDropdown(false);
    }


    return(
        <>
            <SidebarWrapper>
                <div className="sidebar_logo">
                    <img src="/images/Logo.png" alt="logo" />
                    <h3>Ticket <span>Bay</span></h3>
                </div>
                <div className="sidebar_items">
                    <NavLink to="/panel/dashboard">
                        <span></span>
                        <span></span>
                        <p><i className="fa-solid fa-gauge"></i>Dashboard</p>
                    </NavLink>
                    <NavLink to="/panel/theater-register">
                        <span></span>
                        <span></span>
                        <p><i className="fa-solid fa-building-user"></i>Theater Register</p>
                    </NavLink>
                    <div className="dropdown_item">
                        <div className="dropdown_btn">
                            <li className={screenDropdown? 'active':''} onClick={handleScreenDropdown}><i className="fa-solid fa-clapperboard"></i>Screens <i className={`fa-solid fa-chevron-right ${screenDropdown? 'rotate':''}`}></i></li>
                        </div>
                        <div className={`dropdown ${screenDropdown? 'active':''}`}>
                            <NavLink to="/panel/screens">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-circle-info"></i>Screen Details</p>
                            </NavLink>
                            <NavLink to="/panel/seat-layouts">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-couch"></i>Seat Layouts</p>
                            </NavLink>
                            <NavLink to="/panel/screen-settings">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-gears"></i>Screen Settings</p>
                            </NavLink>
                        </div>
                    </div>
                    <div className="dropdown_item">
                        <div className="dropdown_btn">
                            <li className={reportDropdown? 'active':''} onClick={handleReportDropdown}><i className="fa-solid fa-file-lines"></i>Reports <i className={`fa-solid fa-chevron-right ${reportDropdown? 'rotate':''}`}></i></li>
                        </div>
                        <div className={`dropdown ${reportDropdown? 'active':''}`}>
                            <NavLink to="/panel/booking-report">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-ticket"></i>Booking Report</p>
                            </NavLink>
                            <NavLink to="/panel/transaction-report">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-credit-card"></i>Transaction Report</p>
                            </NavLink>
                        </div>
                    </div>
                    <div className="dropdown_item">
                        <div className="dropdown_btn">
                            <li className={movieDropdown? 'active':''} onClick={handleMovieDropdown}><i className="fa-solid fa-film"></i>Movies <i className={`fa-solid fa-chevron-right ${movieDropdown? 'rotate':''}`}></i></li>
                        </div>
                        <div className={`dropdown ${movieDropdown? 'active':''}`}>
                            <NavLink to="/panel/add-movies">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-plus"></i>Select Movie</p>
                            </NavLink>
                            <NavLink to="/panel/all-movies">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-video"></i>All Movies</p>
                            </NavLink>
                            <NavLink to="/panel/today-showing">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-compact-disc"></i>Now Showing</p>
                            </NavLink>
                            <NavLink to="/panel/request-movie">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-code-pull-request"></i>Request Movie</p>
                            </NavLink>
                            <NavLink to="/panel/reviews">
                                <span></span>
                                <span></span>
                                <p><i className="fa-solid fa-star-half-stroke"></i>Reviews</p>
                            </NavLink>
                        </div>
                    </div>
                </div>
            </SidebarWrapper>
        </>
    );
}


export default Sidebar;
import { styled } from "@mui/material";


export const AddMovieWrapper = styled('div')`
    position: relative;
    width: 100%;
    height: 100%;
    padding-top: 15px;

    .wrapper_inner {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 0px 15px;
        display: flex;
        flex-direction: column;

        .header_part {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
                position: relative;
                font-family: 'Lemonada', cursive;
                font-size: 20px;
                font-weight: 600;
                color: #444;
            }

            button {
                position: relative;
                width: 180px;
                height: 55px;
                font-size: 17px;
                font-weight: 500;
                border: none;
                outline: none;
                border-radius: 6px;
                background: #3FE44F;
                color: #FFF;
                cursor: pointer;

                i {
                    position: relative;
                    margin-right: 8px;
                }
            }
        }

        .form_part {
            position: relative;
            width: 100%;
            height: calc(100% - 55px);
            padding-top: 40px;

            .sec_inner {
                position: relative;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                scrollbar-width: none;
                padding-top: 10px;

                form {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    padding-right: 15px;

                    .form_inner {
                        position: relative;
                        width: 100%;
                        display: flex;
                        margin-bottom: 25px;

                        .form_box {
                            position: relative;
                            width: 100%;
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            justify-content: space-between;

                            .selectBox {
                                position: relative;
                                width: 29%;
                                margin-bottom: 25px;

                                input {
                                    display: none;
                                }

                                input.search_input {
                                    display: flex;
                                }

                                &.movie {
                                    width: 39%;
                                }

                                .dropdown_btn {
                                    position: relative;
                                    width: 100%;
                                    height: 55px;
                                    border: 2px solid #E1E0EA;
                                    border-radius: 6px;
                                    display: flex;
                                    align-items: center;
                                    justify-content: space-between;
                                    padding: 5px 25px;
                                    font-family: 'Poppins', sans-serif;
                                    cursor: pointer;

                                    span {
                                        position: absolute;
                                        top: 50%;
                                        left: 25px;
                                        font-family: 'Poppins', sans-serif;
                                        font-size: 16px;
                                        line-height: 1;
                                        color: #555;
                                        transform: translateY(-50%);
                                        transition: 0.5s;
                                    }

                                    p {
                                        position: relative;
                                        max-width: calc(100% - 15px);
                                        padding-right: 15px;
                                        font-size: 16px;
                                        line-height: 1.5;
                                        color: #555;
                                        white-space: nowrap;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }

                                    i {
                                        font-size: 15px;
                                        transition: 0.7s;

                                        &.active {
                                            transform: rotate(-180deg);
                                            transition: 0.5s;
                                        }
                                    }
                                }

                                input:focus ~ .dropdown_btn,
                                input:valid ~ .dropdown_btn {
                                    border: 2px solid #FC6736;
                                    transition: 0.5s;
                                }

                                input:focus ~ .dropdown_btn span,
                                input:valid ~ .dropdown_btn span {
                                    top: 0%;
                                    left: 15px;
                                    font-size: 13px;
                                    padding: 0px 8px;
                                    background: #FFF;
                                    color: #02C0FF;
                                    font-weight: 500;
                                    transition: 0.5s;
                                }

                                .dropdown {
                                    position: absolute;
                                    top: 100%;
                                    left: 0;
                                    width: 100%;
                                    z-index: 15;
                                    background: #FFF;
                                    border-radius: 5px;
                                    box-shadow: 5px 8px 10px rgba(0,0,0,0.4);
                                    max-height: 0;
                                    overflow: hidden;
                                    transition: all 0.8s;

                                    &.active {
                                        max-height: 450px;
                                        transition: all 0.8s;
                                    }

                                    .search_sec {
                                        position: relative;
                                        width: 100%;
                                        padding: 10px;

                                        .search_inner {
                                            position: relative;
                                            width: 100%;
                                            height: 50px;
                                            padding: 7px 0px;
                                            padding-right: 10px;
                                            display: flex;
                                            align-items: center;
                                            border-radius: 6px;
                                            border: 1px solid rgb(212, 212, 212);

                                            i {
                                                position: relative;
                                                width: 50px;
                                                height: 100%;
                                                display: flex;
                                                align-items: center;
                                                justify-content: center;
                                                font-size: 15px;
                                                color: rgb(212, 212, 212);
                                                border-right: 1px solid rgb(212, 212, 212);
                                            }

                                            input {
                                               position: relative;
                                               width: calc(100% - 50px);
                                               height: 100%;
                                               border: none;
                                               outline: none;
                                               padding: 0px 20px;
                                            }
                                        }
                                    }

                                    ul {
                                        position: relative;
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        padding: 10px 10px;
                                        max-height: 350px;
                                        overflow-y: auto;
                                        scrollbar-width: none;

                                        li {
                                            position: relative;
                                            list-style: none;
                                            margin: 5px 0;
                                            padding: 12px 15px;
                                            color: #555;
                                            font-family: 'Poppins', sans-serif;
                                            font-size: 14px;
                                            line-height: 1;
                                            border-radius: 4px;
                                            transition: all 0.5s;
                                            cursor: pointer;

                                            &:hover {
                                                background: #FC6736;
                                                color: #FFF;
                                                transition: all 0.5s;
                                            }
                                        }
                                    }
                                }
                            }

                            .time_box {
                                position: relative;
                                width: 67%;
                                display: flex;
                                align-items: center;
                                margin-bottom: 25px;

                                input {
                                    display: none;
                                }

                                h5 {
                                    position: relative;
                                    font-size: 15px;
                                    font-weight: 500;
                                    color: #444;
                                    margin-right: 15px;
                                }

                                .time_select_box {
                                    position: relative;
                                    display: flex;
                                    align-items: center;

                                    &.slot {
                                        margin-left: 25px;
                                    }

                                    input {
                                        display: none;
                                    }

                                    .select_btn {
                                        position: relative;
                                        width: 85px;
                                        height: 55px;
                                        border: 2px solid #E1E0EA;
                                        border-radius: 6px;
                                        display: flex;
                                        align-items: center;
                                        justify-content: space-between;
                                        padding: 5px 17px;
                                        font-family: 'Poppins', sans-serif;
                                        cursor: pointer;

                                        p {
                                            position: relative;
                                            font-size: 16px;
                                        }

                                        i {
                                            font-size: 15px;
                                            transition: all 0.5s;

                                            &.active {
                                                transform: rotate(-180deg);
                                                transition: all 0.5s;
                                            }
                                        }
                                    }

                                    input:focus ~ .select_btn,
                                    input:valid ~ .select_btn {
                                        border: 2px solid #FC6736;
                                        transition: 0.5s;
                                    }

                                    .options {
                                        position: absolute;
                                        top: 100%;
                                        left: 0;
                                        width: 100%;
                                        z-index: 15;
                                        background: #FFF;
                                        border-radius: 5px;
                                        box-shadow: 5px 8px 15px rgba(0,0,0,0.4);
                                        max-height: 0;
                                        overflow: hidden;
                                        transition: all 0.8s;

                                        &.active {
                                            max-height: 500px;
                                            transition: all 0.8s;
                                        }

                                        ul {
                                            position: relative;
                                            width: 100%;
                                            display: flex;
                                            flex-direction: column;
                                            padding: 10px;
                                            max-height: 250px;
                                            overflow-y: auto;
                                            scrollbar-width: none;

                                            li {
                                                position: relative;
                                                list-style: none;
                                                padding: 10px ;
                                                color: #555;
                                                font-family: 'Poppins', sans-serif;
                                                font-size: 14px;
                                                line-height: 1;
                                                border-radius: 4px;
                                                transition: all 0.5s;
                                                cursor: pointer;

                                                &:hover {
                                                    background: #FC6736;
                                                    color: #FFF;
                                                    transition: all 0.5s;
                                                }
                                            }
                                        }
                                    }

                                }

                                span {
                                    position: relative;
                                    font-size: 18px;
                                    margin: 0px 15px;
                                }
                            }

                            .date_box {
                                position: relative;
                                width: 30%;
                                margin-right: 25px;
                                margin-bottom: 25px;

                                input {
                                    position: relative;
                                    width: 100%;
                                    height: 55px;
                                    border: 2px solid #E1E0EA;
                                    outline: none;
                                    border-radius: 6px;
                                    padding: 5px 25px;
                                    font-family: 'Poppins', sans-serif;
                                    cursor: pointer;
                                    font-size: 16px;
                                    color: #555;
                                }

                                span {
                                    position: absolute;
                                    top: 50%;
                                    left: 25px;
                                    font-family: 'Poppins', sans-serif;
                                    font-size: 16px;
                                    line-height: 1;
                                    color: #555;
                                    transform: translateY(-50%);
                                    transition: 0.5s;
                                    opacity: 0;
                                    pointer-events: none;
                                }

                                input:focus,
                                input:valid {
                                    border: 2px solid #FC6736;
                                    transition: 0.5s;
                                }

                                input:focus ~ span,
                                input:valid ~ span {
                                    top: 0%;
                                    left: 15px;
                                    font-size: 13px;
                                    padding: 0px 8px;
                                    background: #FFF;
                                    color: #02C0FF;
                                    opacity: 1;
                                    font-weight: 500;
                                    transition: 0.5s;
                                }
                            }
                        }
                    }

                    .submit_btn {
                        position: relative;
                        width: 100%;
                        margin-top: 5px;

                        button {
                            position: relative;
                            width: 200px;
                            height: 55px;
                            font-size: 18px;
                            font-weight: 600;
                            text-transform: uppercase;
                            letter-spacing: 0.66px;
                            cursor: pointer;
                            border: none;
                            outline: none;
                            background: #02C0FF;
                            color: #FFF;
                            border-radius: 6px;
                        }
                    }
                }
            }
        }
    }
`;
import { useEffect, useState } from "react";
import { BookingReportWrapper } from "../Styles/Report-Style";
import axios from "axios";
import { Fetch_Active_Screens_URL, Fetch_Movies_Datewise_URL } from "../API/Api";



function BookingReport() {

    const [selectedDate, setSelectedDate] = useState('');
    const [screens, setScreens] = useState([]);
    const [screenShow, setScreenShow] = useState(false);
    const [theater, setTheater] = useState('');
    const [selectScreen, setSelectScreen] = useState('');
    const [selectScreenId, setSelectScreenId] = useState('');
    const [screenDropdown, setScreenDropdown] = useState(false);
    const [movies, setMovies] = useState([]);
    const [selectedMovie, setSelectedMovie] = useState('');
    const [movieDropdown, setMovieDropdown] = useState(false);
    const [movieShow, setMovieShow] = useState(false);


    useEffect(() => {
        const user = localStorage.getItem("User");

        axios.get(`${Fetch_Active_Screens_URL}?user=${user}`)
        .then(res => {
            console.log(res);
            setScreens(res.data.screens);
            setTheater(res.data.theater);
        })
        .catch(err => {
            // console.log(err);
            setScreens([]);
        })
    }, []);

    useEffect(() => {
        if(selectedDate) {
            setScreenShow(true);
        } else {
            setScreenShow(false);
        }
    }, [selectedDate]);

    useEffect(() => {
        if(selectScreen) {
            setMovieShow(true);
        } else {
            setMovieShow(false);
        }
    }, [selectScreen]);

    useEffect(() => {
        if(selectedDate && selectScreen) {
            fetchMovies();
        }
    }, [selectedDate, selectScreen]);

    function handleDate(e) {
        setSelectedDate(e.target.value);
        setSelectedMovie('');
    }

    const handleScreens = () => {
        setScreenDropdown(!screenDropdown);
    };

    function handleMovies() {
        setMovieDropdown(!movieDropdown);
    }

    function fetchMovies() {
        axios.get(`${Fetch_Movies_Datewise_URL}?theater=${theater}&&selectedDate=${selectedDate}&&screen=${selectScreen}&&screenId=${selectScreenId}`)
        .then(res => {
            console.log(res);
            if(res.data.status == "200"){
                setMovies(res.data.movies);
            } else {
                setMovies([]);
            }
        })
        .catch(err => {
            console.log(err);
            setMovies([]);
        })
    }

    return(
        <>
            <BookingReportWrapper>
                <div className="wrapper_inner">
                    <div className="header_part">
                        <h4>See Seat Booking Details ( Date wise )</h4>
                    </div>
                    <div className="filter_sec">
                        <div className="sec_inner">
                            <div className="date_box">
                                <input type="date" id="date" onChange={handleDate} required />
                                <span>Date</span>
                            </div>
                            <div className="selectBox">
                                {
                                    screenShow && <>
                                    <input type="text" value={selectScreen} required />
                                    <div className="dropdown_btn" onClick={handleScreens}>
                                        <p>{selectScreen}</p>
                                        <span>Select Screen</span>
                                        <i className={ `fa-solid fa-angle-down ${screenDropdown? 'active':''}`}></i>
                                    </div>
                                    <div className={`dropdown ${screenDropdown? 'active':''}`}>
                                        <ul>
                                            {
                                                screens.map((screen, index) => 
                                                    <li key={index}
                                                        onClick={() => {
                                                            setSelectScreen(screen.screen)
                                                            setSelectScreenId(screen.screen_id)
                                                            setScreenDropdown(false)
                                                        }}
                                                    >{screen.screen}</li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                    </>
                                }
                            </div>
                            <div className="movieSelectBox">
                                {
                                    movieShow && <>
                                    <input type="text" value={selectedMovie} required />
                                    <div className="dropdown_btn" onClick={handleMovies}>
                                        <p>{selectedMovie}</p>
                                        <span>Select Movie</span>
                                        <i className={ `fa-solid fa-angle-down ${movieDropdown? 'active':''}`}></i>
                                    </div>
                                    <div className={`dropdown ${movieDropdown? 'active':''}`}>
                                        <ul>
                                            {
                                                movies && movies.map((movie, index) => 
                                                    <li key={index}
                                                        onClick={() => {
                                                            setSelectedMovie(movie.movie_name)
                                                            setMovieDropdown(false)
                                                        }}
                                                    >
                                                        <p>{`${movie.movie_name} [ Show time: ${movie.time}, Language: ${movie.language} ]`}</p>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </BookingReportWrapper>
        </>
    );
}


export default BookingReport;
import { styled } from "@mui/material";


export const TransactionReportWrapper = styled('div')`
    position: relative;
    width: 100%;
    padding-top: 10px;

    .wrapper_inner {
        position: relative;
        width: 100%;
        padding: 0px 15px;
        display: flex;
        flex-direction: column;

        .header_part {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
                position: relative;
                font-family: 'Lemonada', cursive;
                font-size: 18px;
                font-weight: 600;
                color: #444;
            }

            button {
                position: relative;
                width: 180px;
                height: 55px;
                font-size: 17px;
                font-weight: 500;
                border: none;
                outline: none;
                border-radius: 6px;
                background: #3FE44F;
                color: #FFF;
                cursor: pointer;

                i {
                    position: relative;
                    margin-right: 8px;
                }
            }
        }

        .filter_sec {
            position: relative;
            margin-top: 35px;
            width: 100%;

            .sec_inner {
                position: relative;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;


                .date_box {
                    position: relative;
                    width: 30%;
                    margin-bottom: 25px;

                    input {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        outline: none;
                        border-radius: 6px;
                        padding: 5px 25px;
                        font-family: 'Poppins', sans-serif;
                        cursor: pointer;
                        font-size: 16px;
                        color: #555;
                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 25px;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        line-height: 1;
                        color: #555;
                        transform: translateY(-50%);
                        transition: 0.5s;
                        opacity: 0;
                        pointer-events: none;
                    }

                    input:focus,
                    input:valid {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ span,
                    input:valid ~ span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        opacity: 1;
                        font-weight: 500;
                        transition: 0.5s;
                    }
                }

                .selectBox {
                    position: relative;
                    width: 20%;
                    margin-bottom: 25px;

                    input {
                        display: none;
                    }

                    input.search_input {
                        display: flex;
                    }

                    &.movie {
                        width: 39%;
                    }

                    .dropdown_btn {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px 25px;
                        font-family: 'Poppins', sans-serif;
                        cursor: pointer;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            transform: translateY(-50%);
                            transition: 0.5s;
                        }

                        p {
                            position: relative;
                            max-width: calc(100% - 15px);
                            padding-right: 15px;
                            font-size: 16px;
                            line-height: 1.5;
                            color: #555;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        i {
                            font-size: 15px;
                            transition: 0.7s;

                            &.active {
                                transform: rotate(-180deg);
                                transition: 0.5s;
                            }
                        }
                    }

                    input:focus ~ .dropdown_btn,
                    input:valid ~ .dropdown_btn {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ .dropdown_btn span,
                    input:valid ~ .dropdown_btn span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }

                    .dropdown {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        z-index: 15;
                        background: #FFF;
                        border-radius: 5px;
                        box-shadow: 5px 8px 10px rgba(0,0,0,0.4);
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.8s;

                        &.active {
                            max-height: 450px;
                            transition: all 0.8s;
                        }

                        .search_sec {
                            position: relative;
                            width: 100%;
                            padding: 10px;

                            .search_inner {
                                position: relative;
                                width: 100%;
                                height: 50px;
                                padding: 7px 0px;
                                padding-right: 10px;
                                display: flex;
                                align-items: center;
                                border-radius: 6px;
                                border: 1px solid rgb(212, 212, 212);

                                i {
                                    position: relative;
                                    width: 50px;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 15px;
                                    color: rgb(212, 212, 212);
                                    border-right: 1px solid rgb(212, 212, 212);
                                }

                                input {
                                    position: relative;
                                    width: calc(100% - 50px);
                                    height: 100%;
                                    border: none;
                                    outline: none;
                                    padding: 0px 20px;
                                }
                            }
                        }

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding: 10px 10px;
                            max-height: 350px;
                            overflow-y: auto;
                            scrollbar-width: none;

                            li {
                                position: relative;
                                list-style: none;
                                margin: 5px 0;
                                padding: 12px 15px;
                                color: #555;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                line-height: 1;
                                border-radius: 4px;
                                transition: all 0.5s;
                                cursor: pointer;

                                &:hover {
                                    background: #FC6736;
                                    color: #FFF;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }

                .movieSelectBox {
                    position: relative;
                    width: 46%;
                    margin-bottom: 25px;

                    input {
                        display: none;
                    }

                    .dropdown_btn {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px 25px;
                        font-family: 'Poppins', sans-serif;
                        cursor: pointer;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            transform: translateY(-50%);
                            transition: 0.5s;
                        }

                        p {
                            position: relative;
                            max-width: calc(100% - 15px);
                            padding-right: 15px;
                            font-size: 16px;
                            line-height: 1.5;
                            color: #555;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        i {
                            font-size: 15px;
                            transition: 0.7s;

                            &.active {
                                transform: rotate(-180deg);
                                transition: 0.5s;
                            }
                        }
                    }

                    input:focus ~ .dropdown_btn,
                    input:valid ~ .dropdown_btn {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ .dropdown_btn span,
                    input:valid ~ .dropdown_btn span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }

                    .dropdown {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        z-index: 15;
                        background: #FFF;
                        border-radius: 5px;
                        box-shadow: 5px 8px 10px rgba(0,0,0,0.4);
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.8s;

                        &.active {
                            max-height: 350px;
                            transition: all 0.8s;
                        }

                        .search_sec {
                            position: relative;
                            width: 100%;
                            padding: 10px;

                            .search_inner {
                                position: relative;
                                width: 100%;
                                height: 50px;
                                padding: 7px 0px;
                                padding-right: 10px;
                                display: flex;
                                align-items: center;
                                border-radius: 6px;
                                border: 1px solid rgb(212, 212, 212);

                                i {
                                    position: relative;
                                    width: 50px;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 15px;
                                    color: rgb(212, 212, 212);
                                    border-right: 1px solid rgb(212, 212, 212);
                                }

                                input {
                                    position: relative;
                                    width: calc(100% - 50px);
                                    height: 100%;
                                    border: none;
                                    outline: none;
                                    padding: 0px 20px;
                                }
                            }
                        }

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding: 10px 10px;
                            max-height: 350px;
                            overflow-y: auto;
                            scrollbar-width: none;

                            li {
                                position: relative;
                                list-style: none;
                                margin: 5px 0;
                                padding: 12px 15px;
                                color: #555;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                line-height: 1;
                                border-radius: 4px;
                                transition: all 0.5s;
                                cursor: pointer;

                                &:hover {
                                    background: #FC6736;
                                    color: #FFF;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }
            }
        }

        .table_sec {
            position: relative;
            width: 100%;
            margin-top: 35px;

            table {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: column;

                thead {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    border: 1px solid #FC6736;
                    background: #FC6736;
                    border-radius: 10px 10px 0 0;

                    tr {
                        position: relative;
                        width: 100%;
                        height: 50px;
                        display: flex;

                        &:first-child  {
                            border-bottom: 1px solid #FFF;

                            th {
                                position: relative;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                font-family: 'Lemonada', cursive;
                                font-size: 14px;
                                color: #FFF;
                                font-weight: 600;
                                border-right: 1px solid #FFF;
                                width: 28%;
                                padding: 0px 25px;
                                justify-content: center;

                                &:first-child {
                                    width: 16%;
                                    padding: 0px 25px;
                                    justify-content: flex-start;
                                }

                                &:last-child {
                                    border-right: none;
                                }
                            }
                        }

                        &:last-child {
                            
                            th {
                                position: relative;
                                height: 100%;
                                display: flex;
                                align-items: center;
                                font-family: 'Lemonada', cursive;
                                font-size: 14px;
                                color: #FFF;
                                font-weight: 600;
                                border-right: 1px solid #FFF;
                                width: 14%;
                                padding: 0px 25px;
                                justify-content: center;

                                &:first-child {
                                    width: 16%;
                                    padding: 0px 25px;
                                    justify-content: flex-start;
                                }

                                &:last-child {
                                    border-right: none;
                                }
                            }
                        }
                    }
                }

                tbody {
                    position: relative;
                    width: 100%;
                    border: 1px solid #E1E0EA;
                    border-radius: 0 0 10px 10px;
                    display: flex;
                    flex-direction: column;
                    overflow-y: auto;
                    scrollbar-width: none;

                    tr {
                        position: relative;
                        width: 100%;
                        min-height: 55px;
                        display: flex;
                        border-bottom: 1px solid #E1E0EA;

                        &:last-of-type {
                            border-bottom: none;
                        }

                        &:nth-of-type(even) {
                            background: rgba(2, 192, 255, 0.15);
                        }

                        p {
                            position: relative;
                            width: 100%;
                            padding: 15px 25px;
                            display: flex;
                            justify-content: center;
                            font-family: 'Poppins', sans-serif;
                            font-size: 15px;
                            line-height: 1.5;
                        }
                    }
                }
            }
        }

        .download_sec {
            position: relative;
            width: 100%;
            margin-top: 40px;
            display: flex;
            justify-content: flex-end;

            button {
                position: relative;
                padding: 14px 40px;
                font-size: 15px;
                border: 2px solid #02C0FF;
                border-radius: 8px;
                background: #02C0FF;
                color: #FFF;
                font-weight: 500;
                cursor: pointer;
                transition: all 0.5s;

                i {
                    position: relative;
                    margin-right: 8px;
                }

                &:hover {
                    background: #FFF;
                    color: #02C0FF;
                    transition: all 0.5s;
                }
            }
        }
    }
`;

export const BookingReportWrapper = styled('div')`
    position: relative;
    width: 100%;
    padding-top: 10px;

    .wrapper_inner {
        position: relative;
        width: 100%;
        padding: 0px 15px;
        display: flex;
        flex-direction: column;

        .header_part {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h4 {
                position: relative;
                font-family: 'Lemonada', cursive;
                font-size: 18px;
                font-weight: 600;
                color: #444;
            }

            button {
                position: relative;
                width: 180px;
                height: 55px;
                font-size: 17px;
                font-weight: 500;
                border: none;
                outline: none;
                border-radius: 6px;
                background: #3FE44F;
                color: #FFF;
                cursor: pointer;

                i {
                    position: relative;
                    margin-right: 8px;
                }
            }
        }

        .filter_sec {
            position: relative;
            margin-top: 35px;
            width: 100%;

            .sec_inner {
                position: relative;
                width: 100%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;


                .date_box {
                    position: relative;
                    width: 30%;
                    margin-bottom: 25px;

                    input {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        outline: none;
                        border-radius: 6px;
                        padding: 5px 25px;
                        font-family: 'Poppins', sans-serif;
                        cursor: pointer;
                        font-size: 16px;
                        color: #555;
                    }

                    span {
                        position: absolute;
                        top: 50%;
                        left: 25px;
                        font-family: 'Poppins', sans-serif;
                        font-size: 16px;
                        line-height: 1;
                        color: #555;
                        transform: translateY(-50%);
                        transition: 0.5s;
                        opacity: 0;
                        pointer-events: none;
                    }

                    input:focus,
                    input:valid {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ span,
                    input:valid ~ span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        opacity: 1;
                        font-weight: 500;
                        transition: 0.5s;
                    }
                }

                .selectBox {
                    position: relative;
                    width: 20%;
                    margin-bottom: 25px;

                    input {
                        display: none;
                    }

                    input.search_input {
                        display: flex;
                    }

                    &.movie {
                        width: 39%;
                    }

                    .dropdown_btn {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px 25px;
                        font-family: 'Poppins', sans-serif;
                        cursor: pointer;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            transform: translateY(-50%);
                            transition: 0.5s;
                        }

                        p {
                            position: relative;
                            max-width: calc(100% - 15px);
                            padding-right: 15px;
                            font-size: 16px;
                            line-height: 1.5;
                            color: #555;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        i {
                            font-size: 15px;
                            transition: 0.7s;

                            &.active {
                                transform: rotate(-180deg);
                                transition: 0.5s;
                            }
                        }
                    }

                    input:focus ~ .dropdown_btn,
                    input:valid ~ .dropdown_btn {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ .dropdown_btn span,
                    input:valid ~ .dropdown_btn span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }

                    .dropdown {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        z-index: 15;
                        background: #FFF;
                        border-radius: 5px;
                        box-shadow: 5px 8px 10px rgba(0,0,0,0.4);
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.8s;

                        &.active {
                            max-height: 450px;
                            transition: all 0.8s;
                        }

                        .search_sec {
                            position: relative;
                            width: 100%;
                            padding: 10px;

                            .search_inner {
                                position: relative;
                                width: 100%;
                                height: 50px;
                                padding: 7px 0px;
                                padding-right: 10px;
                                display: flex;
                                align-items: center;
                                border-radius: 6px;
                                border: 1px solid rgb(212, 212, 212);

                                i {
                                    position: relative;
                                    width: 50px;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 15px;
                                    color: rgb(212, 212, 212);
                                    border-right: 1px solid rgb(212, 212, 212);
                                }

                                input {
                                    position: relative;
                                    width: calc(100% - 50px);
                                    height: 100%;
                                    border: none;
                                    outline: none;
                                    padding: 0px 20px;
                                }
                            }
                        }

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding: 10px 10px;
                            max-height: 350px;
                            overflow-y: auto;
                            scrollbar-width: none;

                            li {
                                position: relative;
                                list-style: none;
                                margin: 5px 0;
                                padding: 12px 15px;
                                color: #555;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                line-height: 1;
                                border-radius: 4px;
                                transition: all 0.5s;
                                cursor: pointer;

                                &:hover {
                                    background: #FC6736;
                                    color: #FFF;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }

                .movieSelectBox {
                    position: relative;
                    width: 46%;
                    margin-bottom: 25px;

                    input {
                        display: none;
                    }

                    .dropdown_btn {
                        position: relative;
                        width: 100%;
                        height: 55px;
                        border: 2px solid #E1E0EA;
                        border-radius: 6px;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 5px 25px;
                        font-family: 'Poppins', sans-serif;
                        cursor: pointer;

                        span {
                            position: absolute;
                            top: 50%;
                            left: 25px;
                            font-family: 'Poppins', sans-serif;
                            font-size: 16px;
                            line-height: 1;
                            color: #555;
                            transform: translateY(-50%);
                            transition: 0.5s;
                        }

                        p {
                            position: relative;
                            max-width: calc(100% - 15px);
                            padding-right: 15px;
                            font-size: 16px;
                            line-height: 1.5;
                            color: #555;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }

                        i {
                            font-size: 15px;
                            transition: 0.7s;

                            &.active {
                                transform: rotate(-180deg);
                                transition: 0.5s;
                            }
                        }
                    }

                    input:focus ~ .dropdown_btn,
                    input:valid ~ .dropdown_btn {
                        border: 2px solid #FC6736;
                        transition: 0.5s;
                    }

                    input:focus ~ .dropdown_btn span,
                    input:valid ~ .dropdown_btn span {
                        top: 0%;
                        left: 15px;
                        font-size: 13px;
                        padding: 0px 8px;
                        background: #FFF;
                        color: #02C0FF;
                        font-weight: 500;
                        transition: 0.5s;
                    }

                    .dropdown {
                        position: absolute;
                        top: 100%;
                        left: 0;
                        width: 100%;
                        z-index: 15;
                        background: #FFF;
                        border-radius: 5px;
                        box-shadow: 5px 8px 10px rgba(0,0,0,0.4);
                        max-height: 0;
                        overflow: hidden;
                        transition: all 0.8s;

                        &.active {
                            max-height: 350px;
                            transition: all 0.8s;
                        }

                        .search_sec {
                            position: relative;
                            width: 100%;
                            padding: 10px;

                            .search_inner {
                                position: relative;
                                width: 100%;
                                height: 50px;
                                padding: 7px 0px;
                                padding-right: 10px;
                                display: flex;
                                align-items: center;
                                border-radius: 6px;
                                border: 1px solid rgb(212, 212, 212);

                                i {
                                    position: relative;
                                    width: 50px;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    font-size: 15px;
                                    color: rgb(212, 212, 212);
                                    border-right: 1px solid rgb(212, 212, 212);
                                }

                                input {
                                    position: relative;
                                    width: calc(100% - 50px);
                                    height: 100%;
                                    border: none;
                                    outline: none;
                                    padding: 0px 20px;
                                }
                            }
                        }

                        ul {
                            position: relative;
                            width: 100%;
                            display: flex;
                            flex-direction: column;
                            padding: 10px 10px;
                            max-height: 350px;
                            overflow-y: auto;
                            scrollbar-width: none;

                            li {
                                position: relative;
                                list-style: none;
                                margin: 5px 0;
                                padding: 12px 15px;
                                color: #555;
                                font-family: 'Poppins', sans-serif;
                                font-size: 14px;
                                line-height: 1;
                                border-radius: 4px;
                                transition: all 0.5s;
                                cursor: pointer;

                                &:hover {
                                    background: #FC6736;
                                    color: #FFF;
                                    transition: all 0.5s;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;